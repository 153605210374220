import store from '@/state/store.js';
import $ from 'jquery';
export default {
  props: {},
  data() {
    return {
      success: '',
      error: '',
      loading: false,
      seriveType: [{
        id: '1',
        name: 'SMS'
      }, {
        id: '2',
        name: 'VOICE'
      }],
      formData: {
        novalidity: false,
        code: '',
        percentage: '',
        expiry_date: '',
        service_type: '',
        limit: '',
        to: ''
      },
      rules: {
        code: [{
          required: true,
          message: 'Please Enter Promo Name.',
          trigger: 'change'
        }],
        expiry_date: [{
          required: true,
          message: 'Please Enter Start Date.',
          trigger: 'change'
        }],
        service_type: [{
          required: true,
          message: 'Please select Service Type.',
          trigger: 'change'
        }],
        percentage: [{
          required: true,
          message: 'Please Enter percentage.',
          trigger: 'change'
        }],
        limit: [{
          required: true,
          message: 'Please Enter Limit.',
          trigger: 'change'
        }],
        to: [{
          required: true,
          message: 'Please Enter Expiry Date.',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    hideModal() {
      $('#sendmessagetoclient').modal('hide');
      this.error = '';
      this.success = '';
      this.loading = false;
      this.formData = {
        code: '',
        service_type: '',
        expiry_date: '',
        limit: '',
        percentage: '',
        to: ''
      };
    },
    createPromoCode() {
      this.loading = true;
      store.dispatch('admin/promocodes/createPromocode', this.formData).then(message => {
        this.success = message;
      }).catch(err => {
        var _err$response;
        if ((err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
          this.errors = "Network error try again.";
        }
        this.$message({
          type: "error",
          message: err.response.data.message
        });
      }).finally(() => this.loading = false);
    },
    submitAction(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.createPromoCode();
        } else {
          this.loading = false;
          return false;
        }
      });
    }
  },
  computed: {
    industries() {
      return store.getters['admin/industries/industries'];
    }
  },
  mounted() {
    this.error = '';
    this.success = '';
    this.loading = false;
    this.formData = {
      code: '',
      service_type: '',
      expiry_date: '',
      limit: '',
      percentage: '',
      to: ''
    };
    store.dispatch('admin/industries/getIndustryReporting');
  }
};