var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "tabs"
  }, [_c('div', {
    staticClass: "side_nav_wrapper"
  }, [_c('div', {
    staticClass: "__side_nav"
  }, [_c('button', {
    staticClass: "btn",
    class: [_vm.$route.path.includes('/admin/promo_codes') ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/promo_codes').catch(err => {});
      }
    }
  }, [_vm._v(" Promos ")]), _c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/referrals' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/referrals').catch(err => {});
      }
    }
  }, [_vm._v(" Referrals ")])])])]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "promo_top_container"
  }, [_c('div', {
    staticClass: "promo_header"
  }, [_c('div', {
    staticClass: "header___wrapper"
  }, [_c('h2', [_vm._v(" Promo Code Overwiew ")]), _c('button', {
    on: {
      "click": _vm.createPromoCode
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/add_promo.svg"),
      "alt": "img"
    }
  }), _vm._v(" Create New Coupon ")])])]), _c('div', {
    staticClass: "__promo_overview"
  }, [_c('div', {
    staticClass: "overview_container _first___"
  }, [_c('p', {
    staticClass: "description"
  }, [_vm._v(" Total promo code used ")]), _c('p', {
    staticClass: "amount"
  }, [_vm._v(" " + _vm._s(_vm.totalPromoCodesUesd) + " ")])]), _c('div', {
    staticClass: "overview_container"
  }, [_c('p', {
    staticClass: "description"
  }, [_vm._v(" Total assigned promo code ")]), _c('p', {
    staticClass: "amount"
  }, [_vm._v(" " + _vm._s(_vm.tableData.length) + " ")])])])]), _vm.loading ? _c('ComponentLoader', {
    attrs: {
      "loading": _vm.loading
    }
  }) : _c('div', {
    staticClass: "__main_card_container"
  }, [_c('div', {
    staticClass: "_main_card_header"
  }, [_c('div', {
    staticClass: "__header_inner admin_header_padding"
  }, [_c('h2', [_vm._v(" " + _vm._s(_vm.$route.meta.header) + " ")]), _c('div', {
    staticClass: "side_nav_wrapper"
  }, [_c('div', {
    staticClass: "__side_nav"
  }, [_c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/promo_codes/all' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/promo_codes/all').catch(err => {});
      }
    }
  }, [_vm._v(" All ")]), _c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/promo_codes/active' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/promo_codes/active').catch(err => {});
      }
    }
  }, [_vm._v(" Active ")]), _c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/promo_codes/inactive' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/promo_codes/inactive').catch(err => {});
      }
    }
  }, [_vm._v(" Inactive ")]), _c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/promo_codes/expired' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/promo_codes/expired').catch(err => {});
      }
    }
  }, [_vm._v(" Expired ")])])])])]), _c('div', {
    staticClass: "main_card_body"
  }, [_c('router-view')], 1)])], 1), _c('createPromoCode', {
    ref: "createpromocode"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };