import moment from 'moment';
import $ from 'jquery';
import store from '@/state/store.js';
import createPromoCode from './createPromocode.vue';
export default {
  name: 'admin-resellers',
  components: {
    createPromoCode
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    createPromoCode() {
      let element = this.$refs.createpromocode.$el;
      $(element).modal('show');
    }
  },
  computed: {
    totalPromoCodesUesd() {
      return store.getters['admin/promocodes/totalPromoCodesUesd'];
    },
    tableData() {
      return store.getters['admin/promocodes/promoCodes'];
    }
  },
  mounted() {
    this.loading = true;
    store.dispatch('admin/promocodes/getPromoCodes').finally(() => this.loading = false);
  }
};